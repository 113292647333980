// Customizable Area Start
import React from "react";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import { Box, Typography, Modal, CircularProgress } from "@material-ui/core";
import TenantCourseMngCreateCatalogModalController, {
  Props,
} from "./TenantCourseMngCreateCatalogModalController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import CloseIcon from "@material-ui/icons/Close";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import CustomStepperWeb from "../../../components/src/CustomStepper.web";
import TenantCourseMngCreateCatalogStep1Web from "./TenantCourseMngCreateCatalogStep1.web";
import TenantCourseMngCreateCatalogStep2Web from "./TenantCourseMngCreateCatalogStep2.web";

// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    createCatalogModal: {
      "& .MuiPaper-rounded": {
        borderRadius: "8px",
      },
    },
    modalMainGrid: {
      borderRadius: "10px",
      minWidth: "500px",
      maxWidth: "70%",
      margin: "auto",
      transform: "translate(-50%, -50%)",
      top: "50%",
      left: "50%",
      overflow: "auto",
      position: "absolute",
      [theme.breakpoints.down("sm")]: {
        minWidth: "100px",
        width: "auto",
        maxWidth: "100%",
      },
    },
    catalogWidth: {
      minWidth: "500px",
      maxWidth: "70%",
    },
    coursesWidth: {
      minWidth: "70%",
      maxWidth: "70%",
    },
    modalGridItemHeader: {
      padding: "10px 24px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    customLoader: {
      height: "140%",
      position: "absolute",
      display: "block",
      top: 0,
      left: 0,
      bottom: -30,
      right: 0,
      zIndex: 100,
      backgroundColor: "rgb(0, 0, 0, .4)",
    },
    circularContainer: {
      transform: "translate(-50%, -50%)",
      position: "absolute",
      top: "40%",
      left: "50%",
    },
  });

// Customizable Area End

export class TenantCourseMngCreateCatalogModal extends TenantCourseMngCreateCatalogModalController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { open, classes, isEdit } = this.props;
    const {
      steps,
      stepperScreen,
      teacherCoursesInputConfigs,
      loading,
      gradeLevelCatalogDDMenuItems,
      subjectsDropdwonListData,
      setAddedCourses,
    } = this.state;
    return (
      <Modal
        open={open}
        className={`${classes.highlightBgColor} ${classes.createCatalogModal}`}
        onClose={this.handleCancelStepperBtn}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        disableBackdropClick
        disableEscapeKeyDown
      >
        <Box className={`${classes.bgColor} ${classes.modalMainGrid} ${stepperScreen === "catalog_details" ? classes.catalogWidth : classes.coursesWidth} `}>
          {loading && (
            <Box className={classes.customLoader}>
              <Box className={classes.circularContainer}>
                <CircularProgress className={`${classes.textButton}`} />
              </Box>
            </Box>
          )}
          <Box className={`${classes.modalGridItemHeader}`}>
            <Typography
              className={`${classes.textPrimaryColor} ${classes.fontText28} ${classes.fontBold600}`}
              component={"span"}
            >
              {isEdit ? 'Edit Catalog' : 'Create New Catalog'}
            </Typography>
            <CloseIcon
              className={`${classes.textCommonColor} ${classes.cursorPointer}`}
              onClick={this.handleCancelStepperBtn}
              id="tenant-create-catalog-close-modal-icon"
            />
          </Box>
          <Box>
            <Box>
              <CustomStepperWeb
                finishLabel="Done"
                nextBtnLabel="Next"
                stepperId="tenant-course-mng-create-new-catalog-stepper"
                prevBtnLabel="Previous"
                hidePreviousBtn
                showCancelBtn
                steps={steps}
                handleNext={this.handleNextStepper}
                handleCancelBtn={this.handleCancelStepperBtn}
                handleSave={this.handleCatalogSave}
                pause={this.handlePauseStepper()}
              >
                {stepperScreen === "catalog_details" && (
                  <TenantCourseMngCreateCatalogStep1Web
                    inputConfigs={teacherCoursesInputConfigs}
                    handleSelectChange={this.handleCatalogSelectDropdownValue}
                    handleTextChange={this.handleCatalogTextChange}
                    renderDDCatalogMenuItem={this.renderCatalogDDMenuItem}
                  />
                )}
                {stepperScreen === "add_courses" && (
                  <TenantCourseMngCreateCatalogStep2Web
                    subjectDropdownData={subjectsDropdwonListData}
                    gradeDropdownData={gradeLevelCatalogDDMenuItems}
                    getAddedCourses={(data: any) => { this.handleAddedCourses(data) }}
                    setAddedCourses={setAddedCourses}
                    catalogId={this.props.catalogId}
                  />
                )}
              </CustomStepperWeb>
            </Box>
          </Box>
        </Box>
      </Modal>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(TenantCourseMngCreateCatalogModal);
// Customizable Area End
