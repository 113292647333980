Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.deleteApiMethod = "DELETE";
exports.patchApiMethod = "PATCH";
exports.putApiMethod = "PUT";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cfcoursemanagement";
exports.labelBodyText = "cfcoursemanagement Body";
exports.getTeacherSchoolYear = "bx_block_school_year/school_years/get_school_years_for_student";
exports.btnExampleTitle = "CLICK ME";
exports.createNewCourse = "bx_block_profile/courses";
exports.getSchoolYearDropdownData = "bx_block_school_year/school_years";
exports.getCurriculumDropdownData = "bx_block_curriculum/curriculums";
exports.getSubjectsDropdwonData = "bx_block_subject/subjects";
exports.getCourseListData = "bx_block_profile/courses";
exports.postCreateNewResource = "bx_block_profile/resources";
exports.postAddNewTopic = "bx_block_profile/topics";
exports.postAddNewLesson = "bx_block_profile/lessons";
exports.postAddNewResource = "bx_block_profile/resources";
exports.getUsersList = "account_block/users";
exports.postAddNewActivity = "bx_block_activity/activities";
exports.questionEndpoint = "bx_block_questionbank/questions";
exports.getGradesDropdownData = "bx_block_grade/grades";
exports.classManagementEndpoint = "bx_block_class_management/class_managements";
exports.fetchStudentsEndpoint = "bx_block_class_management/class_managements/fetch_students";
exports.fetchStudentsEndpointNew = "account_block/users/get_student_based_on_grades_curriculum";
exports.postAddNewCatalog = "bx_block_catalogue/catalogues";
exports.postPublishCourse = "bx_block_request_management/requests";
exports.customFieldEndpoint = "bx_block_custom_field/custom_fields";
exports.getRequestsData = "bx_block_request_management/requests";
exports.assignCatalogToTeacher = "bx_block_catalogue/teacher_catalogues";
exports.studentCoursesEndpoint = "bx_block_profile/student_courses";
exports.teacherClassesEndpoint = "account_block/users/find_teacher_assign_classes";
exports.assignCourseToStudent = "bx_block_profile/class_course_assignments";
exports.getCatalogCourses = "bx_block_catalogue/catalogues/get_catalogue_courses";
exports.getTeacherCoursesEndpoint = "bx_block_profile/courses/get_teacher_courses_tab";
exports.studentMarkActivityCompleted = "bx_block_activity/student_activities";
exports.submitAnswersApiEndpoint = "bx_block_questionbank/student_answers";
exports.teacherClassStudentsApi = "account_block/users/get_class_based_students";
exports.participantsApiEndpoint = "bx_block_profile/participants";
exports.studentProfileProgress = "account_block/users/student_progress";
exports.studentWiseActivities = "bx_block_activity/activities/student_wise_assessments";
exports.userDetailsEndpoint = "account_block/users";
exports.rejectCommentsEndpoint="bx_block_cfthresholdforgrades2/return_grades"
exports.parentTeacherAssigningCourseToStudentEndpoint="bx_block_profile/courses/parent_teacher_assigning_course_to_student"
exports.gradebookStudentDetailsEndpoint = "account_block/users/get_grades_for_particular_student_coursewise"
exports.gradebookStudentAssignmentDetailsEndpoint ="/account_block/users/get_assignment_details_of_course_student"
exports.reviewRequestEndpoint = "bx_block_request_management/requests/review"
// Customizable Area End