// Customizable Area Start
import React from "react";
import { Box, Typography } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import StudentDueAssignmentController, {
  Props,
} from "./StudentDueAssignmentController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import CustomTextEditor from "../../../components/src/CustomTextEditor.web";
import CustomButton from "../../../components/src/CustomButton.web";
import AttachmentIcon from "../../../components/src/SVGIcons/AttachmentIcon.web";
import ClearIcon from "@material-ui/icons/Clear";
// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    answerColorBox: {
      justifyContent: "space-between",
      alignItems: "center",
      display: "flex",
    },
    iconBtn: {
      width: "inherit",
      height: "inherit",
      color: "#ffffff",
    },
    leftMargin: {
      marginLeft: "1%",
    },
    optionGrid: {
      display: "flex",
      alignItems: "center",
    },
    assignmentBox: {
      borderRadius: "10px",
      position: "relative",
      padding: "0px 25px"
    },
    browseInput: {
      opacity: 0,
      cursor: "pointer",
    },
    pageName: {
      marginTop: "16px",
      marginBottom: "28px",
    },
    questionPageNum: {
      padding: "22px 0px",
      borderRadius: "12px",
      overflow: "auto",
    },
    topMargin: {
      marginTop: "25px",
    },
    dueAssessmentMainGrid: {
      width: "98%",
    },
    studentDueAssignmentBox: {
      position: "relative",
      paddingBottom: "30px",
      borderBottom: "1px solid #f1f1f1",
    },
    attachmentListBox: {
      display: "flex",
      gap: "8px",
      marginLeft: "8px",
      maxWidth: "75%",
      overflowX: "auto",
      width: "100%",
      "&::-webkit-scrollbar": {
        width: "7px",
      },
      [theme.breakpoints.down("md")]: {
        maxWidth: "45%",
      },
      [theme.breakpoints.down("xs")]: {
        maxWidth: "17%",
      },
    },
    attachmentItemBox: {
      display: "flex",
      justifyContent: "space-between",
      padding: "10px",
      border: "1px solid #888888",
      borderRadius: "10px",
      width: "155px",
      minWidth: "155px",
      zIndex: 1,
    },
    wordLimitText: {
      color: "red",
      padding: "0px 25px"
    }
  });

// Customizable Area End

// Customizable Area Start
export class StudentDueAssignment extends StudentDueAssignmentController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }

  getAttachIconColor = () => {
    const isDarkTheme = JSON.parse(localStorage.getItem("dark_mode") || "false");
    return isDarkTheme ? "#FBD82C" : "#0947BF"
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, question, index, handleRemoveAnswerAttachment, currentQuestion, handleOpenAttachmentLink } = this.props;
    
    return (
      <Box
        className={classes.assignmentBox}
        key={question?.id}
        display={"flex"}
        flexDirection={"column"}
      >
        <Box padding={"20px 10px 0px 10px"}>
          <Typography
            className={`${classes.textPrimaryColor} ${classes.fontBold600} ${classes.fontText20}`}
          >{`Question ${index + 1}`}</Typography>
          <Typography
            className={`${classes.textPrimaryColor} ${classes.fontText20}`}
            dangerouslySetInnerHTML={{ __html: question?.question }}
          ></Typography>
        </Box>
        {/* Answers list */}
        <Box marginTop={"1%"} sx={{position: "relative"}}>
          <CustomTextEditor
            editorContent={question?.textAnswer}
            editorId={`due-assignment-${question?.id}`}
            onWysEditorStateChange={this.props.handleUpdateAnswer}
            placeHolderText="Enter Answer"
            editorError={false}
            helperText=""
            customHeight="340px"
          />

          <Box sx={{
            position: "absolute",
            bottom: "3%",
            zIndex: 1,
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            boxSizing: "border-box",
          }}
          >
            <Box className={classes.attachmentListBox}>
              {currentQuestion.attachments.map(
                (attachment: any, indexTwo: number) => (
                  <Box
                    key={`Attachment-${attachment.fileSrc}`}
                    className={`${classes.bgColor} ${classes.attachmentItemBox}`}
                  >
                    <Typography
                      data-testid={`assessment-answer-attachment-${
                        currentQuestion.id
                      }-answer-${indexTwo + 1}`}
                      onClick={() =>
                        handleOpenAttachmentLink(
                          attachment.fileSrc
                        )
                      }
                      className={`${classes.textCommonColor} ${classes.fontText16} ${classes.cursorPointer}`}
                      component={"span"}
                    >
                      {`Attachment ${indexTwo + 1}`}
                    </Typography>
                    <ClearIcon
                      data-testid={`assessment-answer-attachment-remove-${
                        currentQuestion.id
                      }-answer-${indexTwo + 1}`}
                      onClick={() =>
                        handleRemoveAnswerAttachment(
                          currentQuestion.id,
                          indexTwo
                        )
                      }
                      className={`${classes.textCommonColor} ${classes.cursorPointer}`}
                    />
                  </Box>
                )
              )}
            </Box>
            
            <input
              data-testid={`assessment-attach-file-${index + 1}`}
              accept="image/png, image/jpg, image/jpeg,"
              type="file"
              multiple
              className={classes.browseInput}
              style={{display: "hidden", position: "absolute", left: "9999px"}}
              onChange={this.handleAttachFileButton}
              ref={this.inputRef}
            />
            <CustomButton
              btnText="Attach File"
              btnStyle={attachFileBtn}
              btnIcon={
                <AttachmentIcon
                  width="28"
                  height="28"
                  viewBox="0 0 28 28"
                  stroke={this.getAttachIconColor()}
                  strokeWidth="2.0"
                  fill="none"
                />
              }
              buttonId={`assessment-attach-file-${question.id}`}
              buttonVariant={"outlined"}
              isDisabled={false}
              type={"button"}
              handleButtonClick={(e: any) => {e.preventDefault(); this.inputRef.current?.click()}}
            />
          </Box>
        </Box>
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const attachFileBtn = {
  width: "155px",
  padding: "10px 10px",
  borderRadius: "12px",
  fontWeight: 600,
  marginRight: "8px"
} as const;
const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(StudentDueAssignment);
// Customizable Area End
